import { Accordion, Badge } from "react-bootstrap";
import ModuleSettings from "../../components/ModuleSettings";
import { FormGroup } from "../../components/FormGroup";
import { FormGroupCheckable } from "../../components/FormGroupCheckable";
import { FormGroupColor } from "../../components/FormGroupColor";
import { FormGroupNumber } from "../../components/FormGroupNumber";
import { SelectLocation } from "../../components/SelectLocation";
import { useRef, useState } from "react";
import { FormGroupCheck } from "../../components/FormGroupCheck";
import { useSitourExport } from "../../hooks/UseSitourExport";
import { extractLifts } from "../../definitions/SitourExportsParsers";
import { Lift } from "../../definitions/SitourExports";

export function LiftsModuleSettings() {
  const liftsKey = useRef<number>(0);
  const [locations, setLocations] = useState<Array<string>>([]);
  const [lifts, setLifts] = useState<Array<Lift>>([]);
  const [numberOfLocations, setNumberOfLocations] = useState(1);

  useSitourExport(
    locations
      .filter((loc) => loc !== "")
      .map((loc) => {
        return "loc_lifts@" + loc;
      })
      .join(","),
    setLifts,
    extractLifts,
    "lifts" + locations.join()
  );

  return (
    <ModuleSettings>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Barvy</Accordion.Header>
          <Accordion.Body style={{ padding: 0 }}>
            <FormGroup labelText="Barva pozadí:">
              <FormGroupCheckable controlId="backColorCheck">
                <FormGroupColor controlId="backColor" />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva pozadí nadpisu:">
              <FormGroupCheckable controlId="headingBackgroundCheck">
                <FormGroupColor controlId="headingBackground" />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva pozadí textu:">
              <FormGroupCheckable controlId="textBackgroundCheck">
                <FormGroupColor controlId="textBackground" />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva textu nadpisu:">
              <FormGroupCheckable controlId="headingTextColorCheck">
                <FormGroupColor controlId="headingTextColor" />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva textu:">
              <FormGroupCheckable controlId="textColorCheck">
                <FormGroupColor controlId="textColor" />
              </FormGroupCheckable>
            </FormGroup>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Lokace</Accordion.Header>
          <Accordion.Body>
            <FormGroup labelText="Počet propojených lokací:">
              <FormGroupNumber
                controlId=""
                onChange={(e) => setNumberOfLocations(parseInt(e.currentTarget.value))}
                defaultValue={1}
                min={1}
                dontUseForm
              />
            </FormGroup>
            {new Array(numberOfLocations).fill(0).map((_, index) => {
              return (
                <Badge key={index} bg="secondary" className="mt-1 w-100">
                  <SelectLocation
                    myKey={index}
                    onChangeRegion={() => {
                      setLocations((locs) => {
                        locs[index] = "";
                        return [...locs];
                      });
                    }}
                    onChangeLocation={(e) =>
                      setLocations((locs) => {
                        const target = e.currentTarget || e.target;
                        locs[index] = target.value;
                        return [...locs];
                      })
                    }
                  />
                </Badge>
              );
            })}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Omezeni</Accordion.Header>
          <Accordion.Body>
            {lifts.map((lift, index) => {
              liftsKey.current++;
              return (
                <FormGroupCheck
                  checked
                  key={liftsKey.current}
                  myKey={index}
                  controlId={lift._id + index}
                  label={lift._id + " - " + lift.name}
                />
              );
            })}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <FormGroup labelText="Velikost nadpisu:">
        <FormGroupNumber controlId="headingSize" defaultValue={25} min={1} />
      </FormGroup>
      <FormGroup labelText="Velikost textu:">
        <FormGroupNumber controlId="size" defaultValue={16} min={1} />
      </FormGroup>
      <FormGroup labelText="Počet sloupců:">
        <FormGroupNumber controlId="cols" defaultValue={2} min={1} />
      </FormGroup>
      <FormGroup labelText="Řádky stejné výšky:">
        <FormGroupCheck controlId="rowsSameSize" label="" checked />
      </FormGroup>
      <FormGroup labelText="Sloupce stejné šířky:">
        <FormGroupCheck controlId="colsSameSize" label="" checked />
      </FormGroup>
      <FormGroup labelText="Mezera mezi řádky:">
        <FormGroupNumber controlId="gap" defaultValue={5} min={0} />
      </FormGroup>
      <FormGroup labelText="Mezičas (milisekundy):">
        <FormGroupNumber controlId="delay" defaultValue={1000} min={0} />
      </FormGroup>
      <FormGroup labelText="Rychlost:">
        <FormGroupNumber controlId="speed" defaultValue={100} min={0} />
      </FormGroup>
    </ModuleSettings>
  );
}
