import { Modules } from "../definitions/General";
import ListGroup from "react-bootstrap/ListGroup";

function moduleSelect(module: Modules) {
  let params = new URLSearchParams();
  params.set("module", module.toString());
  window.location.assign(
    document.location.href.split("?")[0] + "?" + params.toString()
  );
}

export default function ModulesList() {
  return (
    <ListGroup
      style={{ margin: "20vh auto", width: "20vw", minWidth: "fit-content" }}
    >
      <ListGroup.Item action onClick={() => moduleSelect(Modules.CurrentInfo)}>
        Aktuální informace
      </ListGroup.Item>
      <ListGroup.Item action onClick={() => moduleSelect(Modules.CountDown)}>
        Odpočet
      </ListGroup.Item>
      <ListGroup.Item action onClick={() => moduleSelect(Modules.Camera)}>
        Kamera
      </ListGroup.Item>
      <ListGroup.Item action onClick={() => moduleSelect(Modules.Slopes)}>
        Sjezdovky
      </ListGroup.Item>
      <ListGroup.Item action onClick={() => moduleSelect(Modules.Lifts)}>
        Lanovky
      </ListGroup.Item>
      <ListGroup.Item action onClick={() => moduleSelect(Modules.Weather)}>
        Počasí
      </ListGroup.Item>
      <ListGroup.Item action onClick={() => moduleSelect(Modules.Skibuses)}>
        Skibusy
      </ListGroup.Item>
    </ListGroup>
  );
}
