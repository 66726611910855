import { Stack } from "react-bootstrap";
import { FormGroup } from "./FormGroup";
import { FormGroupNumber } from "./FormGroupNumber";
import { useContext, useEffect, useRef, useState } from "react";
import { ModuleSettingsContext } from "./ModuleSettings";
import { ParamsParser } from "../utils/ParamsParser";

export function ModulePreview() {
    const [height, setHeight] = useState(1080);
    const [width, setWidth] = useState(1920);
    const [scale, setScale] = useState(16 / 9);
    const previewFrameContainer = useRef<HTMLDivElement>(null);
    const formData = useContext(ModuleSettingsContext)[0];

    useEffect(() => {
        if (!previewFrameContainer.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if (previewFrameContainer.current?.clientWidth)
                setScale(previewFrameContainer.current.clientWidth / width);
        });
        resizeObserver.observe(previewFrameContainer.current);
        return () => resizeObserver.disconnect();
    }, [setScale, width, previewFrameContainer]);

    const urlResult = window.location.href + "&" + new ParamsParser(undefined, formData).toString();

    return (
        <Stack style={{ height: "100vh" }}>
            <div
                style={{
                    aspectRatio: width / height,
                }}
                className="previewFrameContainer"
                ref={previewFrameContainer}
            >
                <object
                    type="text/html"
                    data={urlResult}
                    title="previwFrame"
                    style={{
                        width: width,
                        height: height,
                        msTransform: "scale(" + scale + ")",
                        ["MozTransform" as any]: "scale(" + scale + ")",
                        WebkitTransform: "scale(" + scale + ")",
                        transform: "scale(" + scale + ")",
                    }}
                    className="previewFrame"
                />
            </div>
            <Stack direction="horizontal" style={{ margin: "auto", marginBottom: 0 }}>
                <FormGroup controlId="x" labelText="X:">
                    <FormGroupNumber
                        defaultValue={width}
                        onChange={(e) => setWidth(parseInt(e.currentTarget.value))}
                        min={0}
                        dontUseForm
                    />
                </FormGroup>
                <FormGroup controlId="y" labelText="Y:">
                    <FormGroupNumber
                        defaultValue={height}
                        onChange={(e) => setHeight(parseInt(e.currentTarget.value))}
                        min={0}
                        dontUseForm
                    />
                </FormGroup>
            </Stack>
            <div
                style={{
                    marginTop: "15px",
                    border: "1px solid black",
                    borderRadius: "5px",
                    background: "#ebb",
                    padding: "10px",
                    lineBreak: "anywhere",
                }}
            >
                {urlResult}
            </div>
        </Stack>
    );
}
