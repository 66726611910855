import {
    ChangeEvent,
    ChangeEventHandler,
    Dispatch,
    SetStateAction,
    useState,
} from "react";
import { FormGroup } from "./FormGroup";
import { FormGroupSelect } from "./FormGroupSelect";
import { Region } from "../definitions/SitourExports";
import { SelectOption } from "../definitions/General";
import { useSitourExport } from "../hooks/UseSitourExport";
import {
    extractLocationsFromRegions,
    extractRegions,
} from "../definitions/SitourExportsParsers";

function getRegionCollection(regions: Array<Region>): Array<SelectOption> {
    let regionCollection: Array<SelectOption> = [
        { text: "Vyber region", value: "0" },
    ];
    regions.forEach((reg) => {
        regionCollection.push({ text: reg._name, value: reg._alias });
    });
    return regionCollection;
}

function regionSelectCallback(
    event: ChangeEvent<HTMLSelectElement>,
    regions: Array<Region>,
    setLocationCollection: Dispatch<SetStateAction<SelectOption[]>>
) {
    setLocationCollection([{ value: "0" }]);
    if (!event.target.selectedIndex || event.target.value === "0") return;
    const locations = extractLocationsFromRegions(
        regions.filter((reg) => reg._alias === event.target.value)
    );
    let locationCollection: Array<SelectOption> = [
        { text: "Vyber lokaci", value: "0" },
    ];
    locations.forEach((loc) => {
        locationCollection.push({ text: loc._name, value: loc._alias });
    });
    setLocationCollection(locationCollection);
}

interface Props {
    onChangeLocation?: ChangeEventHandler<HTMLSelectElement>;
    onChangeRegion?: ChangeEventHandler<HTMLSelectElement>;
    myKey?: number;
    dontUseForm?: boolean;
}

export function SelectLocation({
    onChangeLocation,
    onChangeRegion,
    myKey,
    dontUseForm,
}: Props) {
    const [regionsExport, setRegionsExport] = useState<Array<Region>>([]);
    const [locationCollection, setLocationCollection] = useState<
        Array<SelectOption>
    >([]);

    useSitourExport("loc_info@*", setRegionsExport, extractRegions, "locInfos");

    let regionCollection: Array<SelectOption> =
        getRegionCollection(regionsExport);
    return (
        <>
            <FormGroup labelText="Region:" controlId={"region"}>
                <FormGroupSelect
                    collection={regionCollection}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        regionSelectCallback(
                            e,
                            regionsExport,
                            setLocationCollection
                        );
                        onChangeRegion && onChangeRegion(e);
                    }}
                    defaultValue={""}
                    myKey={myKey}
                    dontUseForm
                />
            </FormGroup>
            <FormGroup labelText="Lokace:" controlId={"location"}>
                <FormGroupSelect
                    collection={locationCollection}
                    onChange={onChangeLocation}
                    defaultValue={""}
                    myKey={myKey}
                    dontUseForm={dontUseForm}
                />
            </FormGroup>
        </>
    );
}
