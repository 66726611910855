import { Modules } from "../definitions/General";
import CountDownModuleSettings from "../modules/Countdown/Settings";
import ModulesList from "./ModulesList";
import CurrentInfoSettings from "../modules/CurrentInfo/Settings";
import CameraModuleSettings from "../modules/Camera/Settings";
import { SlopesModuleSettings } from "../modules/Slopes/Settings";
import { LiftsModuleSettings } from "../modules/Lifts/Settings";
import { ParamsParser } from "../utils/ParamsParser";
import WeatherModuleSettings from "../modules/Weather/Settings";
import { SkibusesModuleSettings } from "../modules/Skibuses/Settings";

interface Props {
  params: ParamsParser;
}

export default function ModulesSettingsMenu({ params }: Props) {
  let moduleParam = params.get("module");
  document.body.style.backgroundColor = "#eee";

  switch (moduleParam) {
    case Modules.CountDown:
      return <CountDownModuleSettings />;
    case Modules.CurrentInfo:
      return <CurrentInfoSettings />;
    case Modules.Camera:
      return <CameraModuleSettings />;
    case Modules.Slopes:
      return <SlopesModuleSettings />;
    case Modules.Lifts:
      return <LiftsModuleSettings />;
    case Modules.Weather:
      return <WeatherModuleSettings />;
    case Modules.Skibuses:
      return <SkibusesModuleSettings />;
    case Modules.None:
    default:
      return <ModulesList />;
  }
}
