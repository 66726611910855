import { useState } from "react";
import { ModuleProps } from "../../definitions/General";
import { useAutoScroll } from "../../hooks/UseAutoScroll";
import { useSitourExport } from "../../hooks/UseSitourExport";
import { Lift } from "../../definitions/SitourExports";
import { Headers, MultiLangStrings } from "../../components/MultiLangStrings";
import { extractLifts } from "../../definitions/SitourExportsParsers";

function getImageByStatus(status: number) {
    let open = false;
    const today = new Date().getDay();
    if (
        status === 1 ||
        (status === 4 && today % 6 === 0) ||
        (status === 5 && !(today % 6 === 0))
    )
        open = true;
    return open ? "./static/images/open.webp" : "./static/images/closed.webp";
}

enum LiftsTypes {
    sedLan1 = 1,
    sedLan2 = 2,
    sedLan3 = 3,
    sedLan4 = 4,
    vlek = 5,
    kabinka = 6,
    koberec = 7,
    sedLan6 = 8,
}

function getItemImgLift(diff: LiftsTypes) {
    switch (diff) {
        case LiftsTypes.sedLan1:
            return "./static/svgs/locinfo_lift_type_1_1s.svg";
        case LiftsTypes.sedLan2:
            return "./static/svgs/locinfo_lift_type_2_2s.svg";
        case LiftsTypes.sedLan3:
            return "./static/svgs/locinfo_lift_type_3_3s.svg";
        case LiftsTypes.sedLan4:
            return "./static/svgs/locinfo_lift_type_4_4s.svg";
        case LiftsTypes.vlek:
            return "./static/svgs/locinfo_lift_type_5_vlek.svg";
        case LiftsTypes.kabinka:
            return "./static/svgs/locinfo_lift_type_6_kab.svg";
        case LiftsTypes.koberec:
            return "./static/svgs/locinfo_lift_type_7_kob.svg";
        case LiftsTypes.sedLan6:
            return "./static/svgs/locinfo_lift_type_8_6s.svg";
    }
}

export function LiftsModule({ params }: ModuleProps) {
    let [lifts, setLifts] = useState<Array<Lift>>([]);
    const locations = params.getAll("location");
    useSitourExport(
        locations
            .map((value) => {
                return "loc_lifts@" + value;
            })
            .join(","),
        setLifts,
        extractLifts,
        "lifts" + locations.join()
    );
    lifts = lifts.filter((lift, index) => params.get(lift._id + index));

    const cols = Number(params.get("cols") ?? 2);
    const rows = Math.ceil(lifts.length / cols);

    let backColor = params.get("backColor");
    if (backColor) document.body.style.background = backColor.toString();

    useAutoScroll(
        Number(params.get("speed") ?? 100),
        Number(params.get("delay") ?? 2000)
    );

    return (
        <div
            className="content"
            style={{
                marginTop: params.get("center") ? "auto" : "",
                marginBottom: params.get("center") ? "auto" : "",
                fontSize: params.get("size") + "px",
                color: params.get("textColor").toString(),
                background: params.get("textBackground").toString(),
            }}
        >
            <div
                className="heading"
                style={{
                    fontSize: params.get("headingSize") + "px",
                    color: params.get("headingTextColor").toString(),
                    background: params.get("headingBackground").toString(),
                }}
            >
                <div>{MultiLangStrings(Headers.lifts)}</div>
            </div>
            <div
                className="info"
                style={{
                    gridTemplateColumns:
                        "repeat(" +
                        cols +
                        (params.get("colsSameSize") ? ",1fr)" : ",auto)"),
                    gridTemplateRows:
                        "repeat(" +
                        rows +
                        (params.get("rowsSameSize") ? ",1fr)" : ",auto)"),
                    gap: params.get("gap") + "px",
                }}
            >
                {lifts?.map((lift, index) => (
                    <div key={index}>
                        <span
                            style={{
                                display: "inline-block",
                                minWidth: "1.6em",
                                textAlign: "center",
                            }}
                        >
                            {lift._id}
                        </span>
                        <img
                            alt=""
                            src={getImageByStatus(Number(lift.status_code))}
                            style={{
                                width: "1em",
                                height: "1em",
                                marginRight: "0.3em",
                            }}
                        />
                        <img
                            alt=""
                            src={getItemImgLift(Number(lift.type_code))}
                            style={{
                                width: "1em",
                                height: "1em",
                                marginRight: "0.3em",
                            }}
                        />
                        <span>{lift.name || ""}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}
