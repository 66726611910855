interface Props {
  text: string | JSX.Element;
  value: string;
}

export function InfoItem({ text, value }: Props) {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ display: "inline-block", width: "70%" }}>{text}</div>
      <div style={{ display: "inline-block", margin: "auto 0" }}>{value}</div>
    </div>
  );
}
