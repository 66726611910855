import { ModuleProps } from "../../definitions/General";
import { CSSProperties, ReactNode, useEffect, useState } from "react";

enum Icons {
    Icon1 = "url(static/svgs/yrno_icon_1.svg)",
    Icon2 = "url(static/svgs/yrno_icon_2.svg)",
    Icon3 = "url(static/svgs/yrno_icon_3.svg)",
    Icon4 = "url(static/svgs/yrno_icon_4.svg)",
    Icon5 = "url(static/svgs/yrno_icon_5.svg)",
    Icon6 = "url(static/svgs/yrno_icon_6.svg)",
    Icon7 = "url(static/svgs/yrno_icon_7.svg)",
    Icon8 = "url(static/svgs/yrno_icon_8.svg)",
    Icon9 = "url(static/svgs/yrno_icon_9.svg)",
    Icon10 = "url(static/svgs/yrno_icon_10.svg)",
    Icon11 = "url(static/svgs/yrno_icon_11.svg)",
    Icon12 = "url(static/svgs/yrno_icon_12.svg)",
    Icon13 = "url(static/svgs/yrno_icon_13.svg)",
    Icon14 = "url(static/svgs/yrno_icon_14.svg)",
    Icon15 = "url(static/svgs/yrno_icon_15.svg)",
    Icon20 = "url(static/svgs/yrno_icon_20.svg)",
    Icon21 = "url(static/svgs/yrno_icon_21.svg)",
    Icon22 = "url(static/svgs/yrno_icon_22.svg)",
    Icon23 = "url(static/svgs/yrno_icon_23.svg)",
}

function Icon({ icon }: { icon?: Icons }) {
    return (
        <div
            style={{
                background: "rgba(0,0,0,0.5)",
                backgroundImage: icon,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "90%",
                width: "100%",
                height: "100%",
                marginLeft: "5px",
            }}
        />
    );
}

function Text({
    children,
    style,
    span2,
    color,
    fontSize,
    background,
}: {
    style?: CSSProperties;
    children?: Array<ReactNode> | ReactNode;
    span2?: boolean;
    color?: string;
    fontSize?: string;
    background?: string;
}) {
    return (
        <div
            style={{
                fontSize: fontSize,
                background: background,
                color: color,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gridColumn: span2 ? "span 2" : "",
                padding: "5px",
                ...style,
            }}
        >
            {children}
        </div>
    );
}

interface yrnoData {
    dt_from: string;
    dt_to: string;
    precipation: string;
    presure: string;
    symbol: string;
    temperature: string;
    winddirdegs: string;
    windspeed: string;
}

interface yrno {
    data: Array<yrnoData>;
    date: string;
}

function getDate(date?: string) {
    const result = new Date(
        Number(date?.slice(0, 4)),
        Number(date?.slice(4, 6)) - 1,
        Number(date?.slice(6, 8)),
        Number(date?.slice(8, 10)),
        Number(date?.slice(10, 12)),
        Number(date?.slice(12, 14))
    );
    return result;
}

function getDateString(date?: string) {
    let result = getDate(date)
        .toLocaleString("cs-CZ", {
            weekday: "long",
            month: "numeric",
            day: "numeric",
        })
        .replace(". ", ".");
    result = result[0].toUpperCase() + result.slice(1);
    return result;
}

function getTimeFromTo(data?: yrnoData) {
    const from = getDate(data?.dt_from);
    const to = getDate(data?.dt_to);
    return `${from.getHours()}:${from
        .getMinutes()
        .toString()
        .padStart(2, "0")}-${to.getHours()}:${to
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
}

function getIcon(icon: string) {
    const iconWithIndex = "Icon" + icon;
    const Icon = Object.entries(Icons).find(
        ([key, _]) => key === iconWithIndex
    );
    return Icon?.[1];
}

export default function WeatherModule({ params }: ModuleProps) {
    const [weather, setWeather] = useState<Array<yrno>>([]);

    useEffect(() => {
        async function getData() {
            const data = await (
                await fetch(
                    "https://api.holidayinfo.cz/yrno.php?alias=" +
                        params.get("location")
                )
            ).json();
            setWeather(data);
        }
        getData();
    }, [setWeather, params]);

    return (
        <div
            style={{
                background: params.get("textBackground")
                    ? params.get("textBackground").toString()
                    : "#aaa",
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: params.get("size") + "px",
            }}
        >
            <div
                style={{
                    background: "rgba(0,0,0,0.25)",
                    color: params.get("textColor")
                        ? params.get("textColor").toString()
                        : "#fff",
                    textAlign: "center",
                    height: "fit-content",
                    width: "fit-content",
                    display: "grid",
                    gap: "9px",
                    padding: "10px",
                    gridTemplate:
                        "1fr 2fr 2fr 2fr 2fr / 2fr 1fr 2fr 1fr 2fr 1fr 2fr",
                }}
            >
                <Text>zdroj: yr.no</Text>
                <Text span2 color="#fed709">
                    {getDateString(weather[0]?.date)}
                </Text>
                <Text span2>{getDateString(weather[1]?.date)}</Text>
                <Text span2>{getDateString(weather[2]?.date)}</Text>
                <Text fontSize="1.4em" background="rgba(0,0,0,0.6)">
                    {getTimeFromTo(weather[0]?.data[0])}
                </Text>
                <Icon icon={getIcon(weather[0]?.data[0].symbol)} />
                <Text
                    fontSize="2.2em"
                    background="rgba(254,215,9,0.75)"
                    color="#000"
                >
                    {weather[0]?.data[0].temperature} °C
                </Text>
                <Icon icon={getIcon(weather[1]?.data[0].symbol)} />
                <Text fontSize="220%" background="rgba(0,0,0,0.75)">
                    {weather[1]?.data[0].temperature} °C
                </Text>
                <Icon icon={getIcon(weather[2]?.data[0].symbol)} />
                <Text fontSize="220%" background="rgba(0,0,0,0.75)">
                    {weather[2]?.data[0].temperature} °C
                </Text>
                <Text fontSize="140%" background="rgba(0,0,0,0.6)">
                    {getTimeFromTo(weather[0]?.data[1])}
                </Text>
                <Icon icon={getIcon(weather[0]?.data[1].symbol)} />
                <Text
                    color="#000"
                    fontSize="220%"
                    background="rgba(254,215,9,0.75)"
                >
                    {weather[0]?.data[1].temperature} °C
                </Text>
                <Icon icon={getIcon(weather[1]?.data[1].symbol)} />
                <Text fontSize="220%" background="rgba(0,0,0,0.75)">
                    {weather[1]?.data[1].temperature} °C
                </Text>
                <Icon icon={getIcon(weather[2]?.data[1].symbol)} />
                <Text fontSize="220%" background="rgba(0,0,0,0.75)">
                    {weather[2]?.data[1].temperature} °C
                </Text>
                <Text fontSize="140%" background="rgba(0,0,0,0.6)">
                    {getTimeFromTo(weather[0]?.data[2])}
                </Text>
                <Icon icon={getIcon(weather[0]?.data[2].symbol)} />
                <Text
                    color="#000"
                    fontSize="220%"
                    background="rgba(254,215,9,0.75)"
                >
                    {weather[0]?.data[2].temperature} °C
                </Text>
                <Icon icon={getIcon(weather[1]?.data[2].symbol)} />
                <Text fontSize="220%" background="rgba(0,0,0,0.75)">
                    {weather[1]?.data[2].temperature} °C
                </Text>
                <Icon icon={getIcon(weather[2]?.data[2].symbol)} />
                <Text fontSize="220%" background="rgba(0,0,0,0.75)">
                    {weather[2]?.data[2].temperature} °C
                </Text>
                <Text fontSize="140%" background="rgba(0,0,0,0.6)">
                    {getTimeFromTo(weather[0]?.data[3])}
                </Text>
                <Icon icon={getIcon(weather[0]?.data[3].symbol)} />
                <Text
                    fontSize="220%"
                    background="rgba(254,215,9,0.75)"
                    color="#000"
                >
                    {weather[0]?.data[3].temperature} °C
                </Text>
                <Icon icon={getIcon(weather[1]?.data[3].symbol)} />
                <Text fontSize="220%" background="rgba(0,0,0,0.75)">
                    {weather[1]?.data[3].temperature} °C
                </Text>
                <Icon icon={getIcon(weather[2]?.data[3].symbol)} />
                <Text fontSize="220%" background="rgba(0,0,0,0.75)">
                    {weather[2]?.data[3].temperature} °C
                </Text>
            </div>
        </div>
    );
}
