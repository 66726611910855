import { Form } from "react-bootstrap";
import {
    CSSProperties,
    ChangeEventHandler,
    useContext,
    useEffect,
} from "react";
import { ModuleSettingsContext } from "./ModuleSettings";
import { controlIdContext } from "./FormGroup";

export interface FormGroupColorProps {
    defaultValue?: string;
    onChange?: ChangeEventHandler;
    controlId?: string;
    myKey?: number;
    style?: CSSProperties;
}
export function FormGroupColor({
    defaultValue,
    onChange,
    controlId,
    myKey,
    style,
}: FormGroupColorProps) {
    const setFormData = useContext(ModuleSettingsContext)[1];
    const controlIdContextValue = useContext(controlIdContext);
    const controlIdString = controlId ? controlId : controlIdContextValue;
    useEffect(() => {
        setFormData({
            type: "create",
            value: {
                name: controlIdString,
                value: defaultValue || "#000000",
                key: myKey,
            },
        });
        return () =>
            setFormData({
                type: "remove",
                name: controlIdString,
                key: myKey,
            });
    }, [setFormData, controlIdString, defaultValue, myKey]);
    return (
        <Form.Control
            style={style}
            id={controlIdString}
            name={controlIdString}
            defaultValue={defaultValue}
            onChange={(e) => {
                onChange && onChange(e);
                setFormData({
                    type: "modify",
                    value: {
                        name: controlIdString,
                        key: myKey,
                        value: e.currentTarget.value,
                    },
                });
            }}
            type="color"
        />
    );
}
