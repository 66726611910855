import { useState } from "react";
import { InfoItems } from "../definitions/General";

export enum Headers {
  lifts = 1000,
  currentInfo,
  slopes,
}

export const multiLangStrings = {
  [Headers.lifts]: ["LANOVKY A VLEKY", "CHAIR LIFTS AND SKI LIFTS"],
  [Headers.currentInfo]: ["AKTUÁLNÍ INFO", "ACTUAL INFO"],
  [Headers.slopes]: ["SJEZDOVÉ TRATĚ", "DOWNHILL ROUTES"],
  [InfoItems.snowHeightSlopes]: ["VÝŠKA SNĚHU", "Height of snow".toUpperCase()],
  [InfoItems.snowHeightNew]: ["NOVÝ SNÍH", "new snow".toUpperCase()],
  [InfoItems.snowTypeText]: ["TYP SNĚHU", "snow".toUpperCase()],
  [InfoItems.snowHeightOutsideSlopes]: [
    "SNÍH MIMO TRATĚ",
    "SNOW OUTSIDE SLOPES",
  ],
  [InfoItems.liftsInOperation]: [
    "LANOVKY/VLEKY V PROVOZU",
    "Number of ski lifts in operation".toUpperCase(),
  ],
  [InfoItems.snowedSlopes]: [
    "Zasněžování".toUpperCase(),
    "Snow making".toUpperCase(),
  ],
  [InfoItems.crossCountryLength]: ["BĚŽECKÉ TRATĚ", "CROSS COUNTRY"],
  [InfoItems.nightSkiingTime]: [
    "VEČERNÍ LYŽOVÁNÍ",
    "Night skiing".toUpperCase(),
  ],
};

export function MultiLangStrings(id: Headers | InfoItems) {
  const [index, setIndex] = useState(0);
  setTimeout(() => setIndex(index + 1), 5000);
  const texts = multiLangStrings[id];
  return texts[index % texts.length];
}

export function MultiLangStringsCustom(strings: Array<string>) {
  const [index, setIndex] = useState(0);
  setTimeout(() => setIndex(index + 1), 5000);
  return strings[index % strings.length];
}
