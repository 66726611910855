import { Country, Location, Lift, Slope, Region } from "./SitourExports";

export function extractRegions(countryExport?: Country) {
  const regionExport = countryExport?.region;
  if (!regionExport) return [];
  if (Array.isArray(regionExport)) return regionExport;
  return [regionExport];
}

export function extractLocationsFromRegions(regions?: Array<Region>) {
  return regions
    ? regions.reduce((final, region) => {
        const locationExport = region?.location;
        if (Array.isArray(locationExport)) final.push(...locationExport);
        else if (locationExport) final.push(locationExport);
        return final;
      }, new Array<Location>())
    : Array<Location>();
}

export function extractLocations(countryExport?: Country) {
  return extractLocationsFromRegions(extractRegions(countryExport));
}

export function extrctCamera(countryExport?: Country) {
  const cams = extractLocations(countryExport)[0].loc_cams?.cam;
  if (Array.isArray(cams)) return cams[0];
  else return cams;
}

export function extractLifts(countryExport?: Country) {
  const locations = extractLocations(countryExport);
  const lifts = locations.reduce((final, location) => {
    const liftsExport = location.loc_lifts?.lift;
    if (Array.isArray(liftsExport)) final.push(...liftsExport);
    else if (liftsExport) final.push(liftsExport);
    return final;
  }, new Array<Lift>());
  return lifts.sort((a, b) => {
    if (a._id < b._id) {
      return -1;
    }
    if (a._id > b._id) {
      return 1;
    }
    return 0;
  });
}

export function extractSlopes(countryExport?: Country) {
  const locations = extractLocations(countryExport);
  return locations
    .reduce((final, location) => {
      const slopesExport = location.loc_slopes?.slope;
      if (Array.isArray(slopesExport)) final.push(...slopesExport);
      else if (slopesExport) final.push(slopesExport);
      return final;
    }, new Array<Slope>())
    .sort((a, b) => {
      const idA = a._id;
      const idB = b._id;
      const idANumber = parseInt(isNaN(+idA) ? idA.slice(0, -1) : idA);
      const idBNumber = parseInt(isNaN(+idA) ? idB.slice(0, -1) : idB);
      return idANumber - idBNumber;
    });
}

export function extractInfo(countryExport?: Country) {
  const loc = extractLocations(countryExport)[0] ?? undefined;
  let result = { _lastchange: "" };
  if (
    loc?.loc_info ||
    loc?.loc_info_winter ||
    loc?.loc_lifts ||
    loc?.loc_slopes ||
    loc?.loc_crosscountry
  )
    result = {
      ...result,
      ...loc.loc_info,
      ...loc.loc_info_winter,
      ...loc.loc_lifts,
      ...loc.loc_slopes,
      ...loc.loc_crosscountry,
      ...loc.loc_cams,
    };
  return result;
}

export function extractLocInfo(countryExport?: Country) {
  const loc = extractLocations(countryExport)[0] ?? undefined;
  return loc?.loc_info_summer ? loc.loc_info_summer : loc?.loc_info_winter;
}
