import { Accordion } from "react-bootstrap";
import ModuleSettings from "../../components/ModuleSettings";
import { FormGroup } from "../../components/FormGroup";
import { FormGroupCheckable } from "../../components/FormGroupCheckable";
import { FormGroupColor } from "../../components/FormGroupColor";
import { FormGroupNumber } from "../../components/FormGroupNumber";
import { SelectLocation } from "../../components/SelectLocation";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { FormGroupSelect } from "../../components/FormGroupSelect";
import { SelectOption } from "../../definitions/General";
import { FormGroupTextArea } from "../../components/FormGroupTextArea";

interface zastavkyData {
    id_objektu: string;
    id_atributu: string;
    hodnota: string;
}

async function locSelectCallback(
    event: ChangeEvent<HTMLSelectElement>,
    setZastavkyCollection: Dispatch<SetStateAction<SelectOption[]>>
) {
    setZastavkyCollection([{ value: "0", text: "Žádné zastávky" }]);
    if (!event.target.selectedIndex || event.target.value === "0") return;

    let zastavkyColl: Array<SelectOption> = [
        { text: "Vyber zastávku", value: "0" },
    ];

    const zastavkyData: Array<zastavkyData> = await (
        await fetch(
            "https://api.holidayinfo.cz/skibus_zastavky.php?alias=" +
                event.target.value
        )
    ).json();

    zastavkyData
        .filter((val) => val.id_atributu === "2")
        .forEach((val) => {
            zastavkyColl.push({ value: val.id_objektu, text: val.hodnota });
        });
    if (zastavkyColl.length !== 1) setZastavkyCollection(zastavkyColl);
    else setZastavkyCollection([{ text: "Žádné zastávky", value: "0" }]);
}

export function SkibusesModuleSettings() {
    const [zastavkyCollection, setZastavkyCollection] = useState<
        Array<SelectOption>
    >([]);

    return (
        <ModuleSettings>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Barvy</Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                        <FormGroup labelText="Barva pozadí:">
                            <FormGroupCheckable controlId="backColorCheck">
                                <FormGroupColor controlId="backColor" />
                            </FormGroupCheckable>
                        </FormGroup>
                        <FormGroup labelText="Barva pozadí nadpisu:">
                            <FormGroupCheckable controlId="headingBackgroundCheck">
                                <FormGroupColor controlId="headingBackground" />
                            </FormGroupCheckable>
                        </FormGroup>
                        <FormGroup labelText="Barva pozadí textu:">
                            <FormGroupCheckable controlId="textBackgroundCheck">
                                <FormGroupColor controlId="textBackground" />
                            </FormGroupCheckable>
                        </FormGroup>
                        <FormGroup labelText="Barva textu nadpisu:">
                            <FormGroupCheckable controlId="headingTextColorCheck">
                                <FormGroupColor controlId="headingTextColor" />
                            </FormGroupCheckable>
                        </FormGroup>
                        <FormGroup labelText="Barva textu:">
                            <FormGroupCheckable controlId="textColorCheck">
                                <FormGroupColor controlId="textColor" />
                            </FormGroupCheckable>
                        </FormGroup>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <SelectLocation
                onChangeLocation={(e) =>
                    locSelectCallback(e, setZastavkyCollection)
                }
            />
            <FormGroup labelText="Zastávka:">
                <FormGroupSelect
                    controlId="zastavka"
                    collection={zastavkyCollection}
                />
            </FormGroup>
            <FormGroup labelText="Nadpis:">
                <FormGroupTextArea
                    controlId="heading"
                    defaultValue={"Skibus"}
                />
            </FormGroup>
            <FormGroup labelText="Velikost nadpisu:">
                <FormGroupNumber
                    controlId="headingSize"
                    defaultValue={35}
                    min={1}
                />
            </FormGroup>
            <FormGroup labelText="Velikost textu:">
                <FormGroupNumber controlId="size" defaultValue={25} min={1} />
            </FormGroup>
            <FormGroup labelText="Počet řádků:">
                <FormGroupNumber
                    controlId="rowsCount"
                    defaultValue={5}
                    min={1}
                />
            </FormGroup>
        </ModuleSettings>
    );
}
