import { useEffect, useState } from "react";
import { ModuleProps } from "../../definitions/General";
import HttpStatusCode from "../../definitions/HttpStatusCodes";
import X2JS from "x2js";

interface SkibusData {
	id_objektu: string;
	id_atributu: string;
	hodnota: string;
}

interface Skibus {
	id_objektu: string;
	nazev?: string;
	zastavky?: Array<string>;
	plany?: any;
	barva?: string;
}

function getDate(date: string, wholeDay: boolean = false) {
	return new Date(new Date(Date.parse(date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6))).getTime() + (wholeDay ? (1000 * 60 * 60 * 24 - 1) : 0));
}

export function SkibusesModule({ params }: ModuleProps) {
	const [nazev, setNazev] = useState<string>("");
	const [prijezdy, setPrijezdy] = useState<Array<any>>([]);

	useEffect(() => {
		async function getData() {
			const data: Array<SkibusData> = await (
				await fetch(
					"https://api.holidayinfo.cz/skibus_zastavky.php?alias=" +
					params.get("location") +
						"&id_zastavky=" +
						params.get("zastavka")
				)
			).json();
			setNazev(data.find((x) => x.id_atributu === "2")?.hodnota || "");
			const busyDataFetch = await fetch(
				"https://api.holidayinfo.cz/skibus_linky.php?alias=" +
				params.get("location") +
					"&id_zastavky=" +
					params.get("zastavka")
			);
			if (!busyDataFetch.ok || busyDataFetch.status === HttpStatusCode.NO_CONTENT) return;
			const busyData: Array<SkibusData> = await busyDataFetch.json();
			const ids = busyData.filter((x) => x.id_atributu === "1").map((x) => x.id_objektu);
			const busy: Array<Skibus> = [];
			ids.forEach((id) => {
				const bus: Skibus = { id_objektu: id };
				busyData
				.filter((x) => x.id_objektu === id)
				.forEach((atr) => {
					switch (atr.id_atributu) {
						case "1":
							bus.nazev = atr.hodnota;
						break;
						case "5":
							bus.zastavky = atr.hodnota.split(",");
						break;
						case "6":
							bus.plany = new X2JS().xml2js(atr.hodnota || "");
						break;
						case "7":
							bus.barva = atr.hodnota;
						break;
					}
				});
				busy.push(bus);
			});
			let busyFiltr: Array<Skibus> = busy.filter(
				(bus) => bus.plany !== null && bus.plany._count !== "0"
			);
			busyFiltr.forEach((bus) => {
				bus.plany =
					bus.plany.timetabs._count === "1"
						? [bus.plany.timetabs.timetab]
						: bus.plany.timetabs.timetab;
						bus.plany = bus.plany
						.filter((plan: any) => plan.journeys._count !== "0")
						.filter((plan: any) => plan._enabled === "1")
						.filter(
							(plan: any) => {
								console.log(getDate(plan._dtrange_end, true));
								return plan._dtrange_enabled !== "1" ||
								(getDate(plan._dtrange_start) <= new Date() &&
								 getDate(plan._dtrange_end, true) >= new Date());
							}
						)
						.filter(
							(plan: any) =>
							plan._weekdays_enabled !== "1" ||
								plan["_weekday_" + ((new Date().getDay() + 6) % 7)] === "1"
						);
			});
			busyFiltr = busyFiltr.filter((bus) => bus.plany.length !== 0);
			for await (const bus of busyFiltr) {
				const idx = bus.zastavky?.findIndex((x) => x === params.get("zastavka").toString());
				bus.plany = bus.plany
				.reduce((total: Array<any>, plan: any) => {
					total.push(...plan.journeys.journey);
					return total;
				}, [])
				.map((plan: any) => {
					return {
						time: plan.times.time.find((time: any) => time._idx === idx?.toString())
						._time,
						barva: bus.barva,
					};
				}).filter((x: any) => x.time !== "");
				const konecna = bus.zastavky?.slice(-1)[0];
				if (bus.plany.length > 0) {
					const konecnaData: Array<SkibusData> = await (
						await fetch(
							"https://api.holidayinfo.cz/skibus_zastavky.php?alias=" +
							params.get("location") +
								"&id_zastavky=" +
								konecna
						)
					).json();
					bus.plany.forEach((x: any) => {
						x.konecna = konecnaData.find((x) => x.id_atributu === "2")?.hodnota || "";
					});
				}
				bus.plany.forEach((x: any) => (x.time = new Date("1900 " + x.time)));
			}
			const now = new Date();
			now.setFullYear(1900);
			now.setDate(1);
			now.setMonth(0);
			now.setSeconds(0);
			now.setMilliseconds(0);
			busyFiltr = busyFiltr
			.reduce((total: Array<any>, bus: Skibus) => {
				total.push(...bus.plany);
				return total;
			}, [])
			.sort((x: any, y: any) => x.time.getTime() - y.time.getTime())
			.filter((x: any) => x.time >= now)
			.slice(0, Number(params.get("rowsCount")));
			setPrijezdy(busyFiltr);
		}
		getData();
	}, [setNazev, params, setPrijezdy]);

	let backColor = params.get("backColor");
	if (backColor) document.body.style.background = backColor.toString();

	return (
		<div
		className="content"
		style={{
			marginTop: params.get("center") ? "auto" : "",
			marginBottom: params.get("center") ? "auto" : "",
			fontSize: params.get("size") + "px",
			color: params.get("textColor").toString(),
			background: params.get("textBackground").toString(),
		}}
		>
		<div
		className="heading"
		style={{
			fontSize: params.get("headingSize") + "px",
			color: params.get("headingTextColor").toString(),
			background: params.get("headingBackground").toString(),
		}}
		>
		<div>{params.get("heading") || "Skibus"}</div>
		</div>
		<div
		style={{
			display: "grid",
			gridAutoFlow: "row",
			overflow: "hidden",
			gridTemplateRows: "1fr, repeat(" + params.get("rowsCount") + ",1fr)",
			border: "solid 10px #14161B",
			margin: "5px",
		}}
		>
		<div style={{
			display:"grid",
			gridTemplateColumns: "15% 85%",
		}}>
		<div
		style={{
			background: "linear-gradient(#3E3E3E,#080808)",
			padding: "8px",
			fontSize: "1em",
			border: "solid 1px #434544",
			textAlign: "center",
		}}
		>
		Odjezd
		</div>
		<div
		style={{
			background: "linear-gradient(#3E3E3E,#080808)",
			padding: "8px 8px 8px 16px",
			fontSize: "1em",
			border: "solid 1px #434544",
		}}
		>
		{nazev}
		</div>
		</div>
		{prijezdy.map((bus, index) => (
			<div key={index} style={{
				display:"grid",
				gridTemplateColumns: "15% 85%",
			}}>
			<div
			style={{
				background: "#" + bus.barva,
				padding: "8px",
				fontSize: "1em",
				border: "solid 3px #fff8",
				borderRadius: "3px",
				textAlign: "center",
			}}
			>
			{bus.time.getHours() +
				":" +
				bus.time.getMinutes().toString().padStart(2, "0")}
			</div>
			<div
			style={{
				background: "#" + bus.barva,
				padding: "8px 8px 8px 16px",
				fontSize: "1em",
				border: "solid 3px #fff8",
				borderRadius: "3px",
			}}
			>
			{bus.konecna}
			</div>
			</div>
		))}
		</div>
		</div>
	);
}
