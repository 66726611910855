import {
    CSSProperties,
    ChangeEventHandler,
    useContext,
    useEffect,
} from "react";
import { Form } from "react-bootstrap";
import { ModuleSettingsContext } from "./ModuleSettings";
import { controlIdContext } from "./FormGroup";

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

export interface FormGroupNumberProps {
    defaultValue?: number;
    onChange?: ChangeEventHandler<FormControlElement>;
    controlId?: string;
    max?: number;
    min?: number;
    myKey?: number;
    dontUseForm?: boolean;
    style?: CSSProperties;
    id?: string;
}

export function FormGroupNumber({
    defaultValue,
    onChange,
    controlId,
    max,
    min,
    myKey,
    dontUseForm,
    style,
    id,
}: FormGroupNumberProps) {
    const setFormData = useContext(ModuleSettingsContext)[1];
    const controlIdContextValue = useContext(controlIdContext);
    const controlIdString = controlId ? controlId : controlIdContextValue;
    useEffect(() => {
        if (!dontUseForm)
            setFormData({
                type: "create",
                value: {
                    name: controlIdString,
                    value: defaultValue || 0,
                    key: myKey,
                },
            });
        return () =>
            setFormData({
                type: "remove",
                name: controlIdString,
                key: myKey,
            });
    }, [setFormData, controlIdString, defaultValue, dontUseForm, myKey]);
    return (
        <Form.Control
            style={style}
            max={max}
            min={min}
            id={id ? id : controlIdString}
            name={controlIdString}
            defaultValue={defaultValue}
            onChange={(e) => {
                if (!e.currentTarget.value) e.currentTarget.value = "0";
                if (onChange) onChange(e);
                const value = parseInt(e.currentTarget.value);
                if (max && value > max) e.currentTarget.value = max.toString();
                else if (min && value < min)
                    e.currentTarget.value = min.toString();
                if (!dontUseForm)
                    setFormData({
                        type: "modify",
                        value: {
                            name: controlIdString,
                            key: myKey,
                            value: e.currentTarget.value,
                        },
                    });
            }}
            type="number"
        />
    );
}
