import { Accordion, Badge, Form } from "react-bootstrap";
import { FormGroup } from "../../components/FormGroup";
import { FormGroupCheckable } from "../../components/FormGroupCheckable";
import { FormGroupColor } from "../../components/FormGroupColor";
import { FormGroupNumber } from "../../components/FormGroupNumber";
import { FormGroupSelect } from "../../components/FormGroupSelect";
import { SelectLocation } from "../../components/SelectLocation";
import { SelectOption } from "../../definitions/General";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { useSitourExport } from "../../hooks/UseSitourExport";
import { extractLocations } from "../../definitions/SitourExportsParsers";
import { Location } from "../../definitions/SitourExports";
import ModuleSettings from "../../components/ModuleSettings";
import { FormGroupTextArea } from "../../components/FormGroupTextArea";
import { FormGroupCheck } from "../../components/FormGroupCheck";

function locSelectCallback(
    event: ChangeEvent<HTMLSelectElement>,
    locations: Array<Location>,
    setCamsCollection: Dispatch<SetStateAction<SelectOption[]>>
) {
    setCamsCollection([{ value: "0", text: "Žádné kamery/videa" }]);
    if (!event.target.selectedIndex || event.target.value === "0") return;
    let camsColl: Array<SelectOption> = [{ text: "Vyber kameru", value: "0" }];
    const location = locations.find((loc) => loc._alias === event.target.value);
    const camExport = location?.loc_cams?.cam;
    if (!camExport) return;
    const cam = Array.isArray(camExport) ? camExport : [camExport];
    cam.forEach((cam) => {
        if (cam._hasvideo === "1")
            camsColl.push({ text: cam.name, value: cam._id });
    });
    if (camsColl.length === 1)
        camsColl = [{ value: "0", text: "Žádné kamery/videa" }];
    setCamsCollection(camsColl);
}

export default function CameraModuleSettings() {
    const [numberOfTexts, setNumberOfTexts] = useState(0);
    const [camsCollection, setCamsCollection] = useState<Array<SelectOption>>(
        []
    );
    const [locationsExport, setLocationsExport] = useState<Array<Location>>([]);

    useSitourExport(
        "loc_cams@*",
        setLocationsExport,
        extractLocations,
        "allCams"
    );

    return (
        <ModuleSettings noCenter>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Barvy</Accordion.Header>
                    <Accordion.Body>
                        <FormGroup
                            labelText="Barva pozadí textu:"
                            controlId="textBackgroundCheck"
                        >
                            <FormGroupCheckable>
                                <FormGroupColor
                                    controlId="textBackground"
                                    defaultValue="#6666ee"
                                />
                                <FormGroupNumber
                                    style={{ width: "50%" }}
                                    controlId="textBackgroundOpacity"
                                    min={0}
                                    max={255}
                                    defaultValue={130}
                                />
                            </FormGroupCheckable>
                        </FormGroup>
                        <FormGroup
                            labelText="Barva textu:"
                            controlId="textColorCheck"
                        >
                            <FormGroupCheckable>
                                <FormGroupColor controlId="textColor" />
                            </FormGroupCheckable>
                        </FormGroup>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Texty</Accordion.Header>
                    <Accordion.Body className="d-grid text-center">
                        <FormGroup labelText="Nadpis:" controlId="heading">
                            <FormGroupTextArea defaultValue={"<N>"} />
                        </FormGroup>
                        <FormGroup controlId="" labelText="Počet textů:">
                            <FormGroupNumber
                                id="textsNumber"
                                onChange={(e) =>
                                    setNumberOfTexts(
                                        parseInt(e.currentTarget.value)
                                    )
                                }
                                defaultValue={0}
                                min={0}
                                dontUseForm
                            />
                        </FormGroup>
                        {new Array(numberOfTexts).fill(0).map((_, index) => {
                            return (
                                <Badge
                                    key={index}
                                    bg="secondary"
                                    className="mt-1 w-100"
                                >
                                    <FormGroup
                                        key={index}
                                        labelText="Text:"
                                        controlId="text"
                                    >
                                        <FormGroupTextArea myKey={index} />
                                    </FormGroup>
                                </Badge>
                            );
                        })}
                        <Badge
                            bg="info"
                            className="m-auto mt-2 fs-5 text-start"
                        >
                            <Form.Text muted>
                                &lt;N&gt; - Název střediska
                                <br />
                                &lt;OD&gt; - otvírací doba
                                <br />
                                &lt;VS&gt; - výška sněhu
                                <br />
                                &lt;NV&gt; - nadmořská výška
                                <br />
                                &lt;T&gt; - teplota
                                <br />
                                &lt;WWW&gt; - webové stránky
                                <br />
                                &lt;V1&gt; - 1. věta
                                <br />
                                &lt;V2&gt; - 2. věta
                                <br />
                                &lt;V3&gt; - 3. věta
                                <br />
                                &lt;V4&gt; - 4. věta
                                <br />
                                <br />
                                &lt;OTime&gt; - obrázek hodin
                                <br />
                                &lt;OTemp&gt; - obrázek teploměru
                                <br />
                                &lt;OVlocka&gt; - obrázek vločky
                                <br />
                                <br />
                                <span style={{ fontSize: "0.85em" }}>
                                    Pokud nebude parametr existovat celý řádek
                                    se nezobrazí.
                                </span>
                            </Form.Text>
                        </Badge>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <FormGroup labelText="Zobrazit logo Sitour" controlId="logo">
                <FormGroupCheck checked label="" />
            </FormGroup>
            <FormGroup labelText="Velikost textu:" controlId="size">
                <FormGroupNumber defaultValue={45} min={1} />
            </FormGroup>
            <FormGroup labelText="Počet řádků:" controlId="rows">
                <FormGroupNumber defaultValue={1} min={1} />
            </FormGroup>
            <SelectLocation
                onChangeLocation={(e) =>
                    locSelectCallback(e, locationsExport, setCamsCollection)
                }
            />
            <FormGroup labelText="Kamera:" controlId="cam">
                <FormGroupSelect collection={camsCollection} />
            </FormGroup>
            <FormGroup
                labelText="Interval změny textu(ms):"
                controlId="textTime"
            >
                <FormGroupNumber defaultValue={8000} />
            </FormGroup>
        </ModuleSettings>
    );
}
