import { InfoItems, ModuleProps } from "../../definitions/General";
import { useState } from "react";
import { useSitourExport } from "../../hooks/UseSitourExport";
import {
  LocCrosscountry,
  LocInfoWinter,
  LocLifts,
  LocSlopes,
} from "../../definitions/SitourExports";
import { InfoItem } from "../../components/InfoItem";
import { useAutoScroll } from "../../hooks/UseAutoScroll";
import { Headers, MultiLangStrings } from "../../components/MultiLangStrings";
import { extractInfo } from "../../definitions/SitourExportsParsers";

function getLiftsRatio(sitourExport?: LocInfoWinter & LocLifts & LocCrosscountry & LocSlopes) {
  let today = new Date().getDay();
  let lifts = Array.isArray(sitourExport?.lift) ? sitourExport?.lift : [sitourExport?.lift];
  return lifts?.reduce(
    (total, lift) => {
      let add = false;
      switch (lift?.status_code) {
        case "1":
          add = true;
          break;
        case "4":
          if (today % 6 === 0) add = true;
          break;
        case "5":
          if (!(today % 6 === 0)) add = true;
          break;
      }
      if (add)
        if (!["5", "7"].includes(lift?.type_code ? lift.type_code : "")) total.lanovky++;
        else total.vleky++;
      return total;
    },
    { lanovky: 0, vleky: 0 }
  );
}

function getInfo(
  item: InfoItems,
  sitourExport?: LocInfoWinter & LocLifts & LocCrosscountry & LocSlopes
) {
  let result: string = "";
  switch (item) {
    case InfoItems.snowHeightSlopes:
      result = (sitourExport?.snowheight_slopes ? sitourExport.snowheight_slopes : "---") + " cm";
      break;
    case InfoItems.snowHeightNew:
      result = (sitourExport?.snowheight_new ? sitourExport.snowheight_new : "-") + " cm";
      break;
    case InfoItems.snowTypeText:
      result = sitourExport?.snowtype_text ? sitourExport.snowtype_text : "";
      break;
    case InfoItems.snowHeightOutsideSlopes:
      const snowHeightOutsideSlopes = sitourExport?.snowheight_outside_slopes;
      result = (snowHeightOutsideSlopes ? snowHeightOutsideSlopes : "-") + " cm";
      break;
    case InfoItems.liftsInOperation:
      let lifts = getLiftsRatio(sitourExport);
      result = lifts?.lanovky + "/" + lifts?.vleky;
      break;
    case InfoItems.snowedSlopes:
      const slopesExport = sitourExport?.slope;
      const slopes = Array.isArray(slopesExport) ? slopesExport : [slopesExport];
      let slopesLength = slopes?.reduce((total, slope) => {
        const length = slope?.length?.replace(",", ".");
        return total + (slope?.snowmaking_code === "2" && length ? parseFloat(length) : 0);
      }, 0);
      if (!slopesLength) slopesLength = 0;
      result = (slopesLength / 1000).toFixed(0) + " km";
      break;
    case InfoItems.crossCountryLength:
      const crossExport = sitourExport?.crosscountry;
      let crosscountryLength = Array.isArray(crossExport)
        ? crossExport?.reduce((total, crosscountry) => {
            const length = crosscountry.length?.replace(",", ".");
            return total + (length ? parseFloat(length) : 0);
          }, 0)
        : crossExport?.length || 0;
      result = crosscountryLength + " km";
      break;
    case InfoItems.nightSkiingTime:
      let start = sitourExport?.nightskiingtime_start ? sitourExport.nightskiingtime_start : "";
      let end = sitourExport?.nightskiingtime_end ? sitourExport.nightskiingtime_end : "";
      result = start + "-" + end;
      break;
  }
  return result;
}

export function CurrentInfoModule({ params }: ModuleProps) {
  const [sitourExport, setExport] = useState<
    LocInfoWinter & LocLifts & LocCrosscountry & LocSlopes
  >();
  const showItems = Object.keys(InfoItems)
    .filter((val) => params.get(val.toString()))
    .map((val) => parseInt(val));
  const cols = Number(params.get("cols") ?? 2);
  const rows = Math.ceil(showItems.length / cols);

  const backColor = params.get("backColor");
  if (backColor) document.body.style.background = String(backColor);

  useSitourExport(
    "loc_info_winter@*,loc_slopes@*,loc_lifts@*,loc_crosscountry@*".replaceAll(
      "*",
      params.get("location") + ""
    ),
    setExport,
    extractInfo,
    "currentInfo" + params.get("location")
  );

  useAutoScroll(Number(params.get("speed") ?? 100), Number(params.get("delay") ?? 2000));

  return (
    <div
      className="content"
      style={{
        marginTop: params.get("center") ? "auto" : "",
        marginBottom: params.get("center") ? "auto" : "",
        fontSize: params.get("size") + "px",
        color: params.get("textColor").toString(),
        background: params.get("textBackground").toString(),
      }}
    >
      <div
        className="heading"
        style={{
          fontSize: params.get("headingSize") + "px",
          color: params.get("headingTextColor").toString(),
          background: params.get("headingBackground").toString(),
        }}
      >
        <div style={{ display: "inline-block" }}>{MultiLangStrings(Headers.currentInfo)}</div>
        <div style={{ float: "right" }}>
          {new Date().getDate()}.{new Date().getMonth() + 1}.
        </div>
      </div>
      <div
        className="info"
        style={{
          gridTemplateColumns: "repeat(" + cols + (params.get("colsSameSize") ? ",1fr)" : ",auto)"),
          gridTemplateRows: "repeat(" + rows + (params.get("rowsSameSize") ? ",1fr)" : ",auto)"),
          gap: params.get("gap") + "px",
        }}
      >
        {showItems.map((val: InfoItems, index) => (
          <InfoItem key={index} text={MultiLangStrings(val)} value={getInfo(val, sitourExport)} />
        ))}
      </div>
    </div>
  );
}
