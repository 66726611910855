import { useEffect } from "react";
import { Country, SitourExport } from "../definitions/SitourExports";
import X2JS from "x2js";

export function useSitourExport<T>(
    specs: string,
    setValue: (value: T) => void,
    countryParser: (country: Country | undefined) => T,
    localDataString: string
) {
    useEffect(() => {
        const localData = localStorage.getItem(localDataString);
        if (localData) setValue(countryParser(getCountryFromExport(localData)));
        fetchSitourExports(specs)?.then((sitourExport) => {
            if (sitourExport && sitourExport !== localData) {
                setValue(countryParser(getCountryFromExport(sitourExport)));
                localStorage.setItem(localDataString, sitourExport);
            }
        });
    }, [specs, setValue, countryParser, localDataString]);
}

export async function useSitourExportWithoutParser(
    specs: string,
    setCountry: (country: Country | undefined) => void,
    localDataString: string
) {
    useEffect(() => {
        const localData = localStorage.getItem(localDataString);
        if (localData) setCountry(getCountryFromExport(localData));
        fetchSitourExports(specs).then((sitourExport) => {
            if (sitourExport && sitourExport !== localData) {
                setCountry(getCountryFromExport(sitourExport));
                localStorage.setItem(localDataString, sitourExport);
            }
        });
    }, [specs, setCountry, localDataString]);
}

function getCountryFromExport(data: string) {
    const sitourExport: SitourExport = new X2JS().xml2js(data);
    const country = Array.isArray(sitourExport.export.country)
        ? sitourExport.export.country[0]
        : sitourExport.export.country;
    return country;
}

async function fetchSitourExports(specs: string) {
    if (specs && window.navigator.onLine)
        return await (
            await fetch(
                "https://exports.holidayinfo.cz/xml_export.php?dc=muwvw3tw30bpng1w&spec=" +
                    specs,
                { method: "get", mode: "cors", headers: {} }
            )
        ).text();
    return undefined;
}
