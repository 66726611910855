import { useEffect } from "react";
import { animateScroll } from "react-scroll";

export function useAutoScroll(speed: number, delay: number) {
  useEffect(() => {
    let duration =
      (Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      ) *
        1000) /
      speed;

    let up = true;
    let autoScroll = () => {
      if (up) {
        animateScroll.scrollToBottom({
          delay: delay,
          duration: duration,
          smooth: "easeInOutQuad",
        });
      } else {
        animateScroll.scrollToTop({
          delay: delay,
          duration: duration,
          smooth: "easeInOutQuad",
        });
      }
      up = !up;
    };

    autoScroll();
    const autoScrollInterval = setInterval(autoScroll, delay + duration);
    return () => clearInterval(autoScrollInterval);
  }, [delay, speed]);
}
