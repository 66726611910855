import { ReactNode, createContext } from "react";
import { Form, Stack } from "react-bootstrap";

export interface FormGroupProps {
    labelText: string;
    children?: ReactNode;
    block?: boolean;
    controlId?: string;
}

export const controlIdContext = createContext("");

export function FormGroup({
    labelText,
    children,
    block,
    controlId,
}: FormGroupProps) {
    return (
        <controlIdContext.Provider value={controlId ? controlId : ""}>
            <Form.Group>
                <Stack
                    direction="horizontal"
                    className="m-1 align-items-center"
                >
                    <Form.Label
                        htmlFor={controlId}
                        className={"col-4 m-auto" + (block ? " mt-0" : "")}
                        style={{ textAlign: "end" }}
                    >
                        {labelText}
                    </Form.Label>
                    <div
                        className="col-8 ms-2"
                        style={{
                            display: block ? "" : "inline-flex",
                            height: block ? "fit-content" : "38px",
                        }}
                    >
                        {children}
                    </div>
                </Stack>
            </Form.Group>
        </controlIdContext.Provider>
    );
}
