import { Modules } from "../definitions/General";
import CameraModule from "../modules/Camera/Module";
import { CountdownModule } from "../modules/Countdown/Module";
import { CurrentInfoModule } from "../modules/CurrentInfo/Module";
import { LiftsModule } from "../modules/Lifts/Module";
import { SkibusesModule } from "../modules/Skibuses/Module";
import { SlopesModule } from "../modules/Slopes/Module";
import WeatherModule from "../modules/Weather/Module";
import { ParamsParser } from "../utils/ParamsParser";

interface Props {
  params: ParamsParser;
}

export default function ModulesSwitch({ params }: Props) {
  const moduleIndex = params.get("module");
  document.body.style.overflowY = "hidden";

  switch (moduleIndex) {
    case Modules.CountDown:
      return <CountdownModule params={params} />;
    case Modules.CurrentInfo:
      return <CurrentInfoModule params={params} />;
    case Modules.Camera:
      return <CameraModule params={params} />;
    case Modules.Slopes:
      return <SlopesModule params={params} />;
    case Modules.Lifts:
      return <LiftsModule params={params} />;
    case Modules.Weather:
      return <WeatherModule params={params} />;
    case Modules.Skibuses:
      return <SkibusesModule params={params} />;
    default:
      return <>Něco se pokazilo :D</>;
  }
}
