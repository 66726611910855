import { useEffect, useState } from "react";
import { LocInfoSummer, LocInfoWinter } from "../../definitions/SitourExports";
import { useSitourExport } from "../../hooks/UseSitourExport";
import { ModuleProps } from "../../definitions/General";
import { MultiLangStringsCustom } from "../../components/MultiLangStrings";
import { extractLocInfo } from "../../definitions/SitourExportsParsers";

function opertimeToDate(opertime?: string) {
    const time = new Date();
    if (!opertime) return time;

    const opertimeSplit = opertime?.split(":");
    time.setHours(parseInt(opertimeSplit?.[0]));
    time.setMinutes(parseInt(opertimeSplit[1]));
    time.setSeconds(0);
    return time;
}

function dateToString(date: Date) {
    return (
        date.getUTCHours().toString().padStart(2, "0") +
        ":" +
        date.getUTCMinutes().toString().padStart(2, "0") +
        ":" +
        date.getUTCSeconds().toString().padStart(2, "0")
    );
}

function isOpen(operCode?: string) {
    const date = new Date().getDay() - 1;
    switch (parseInt(operCode || "")) {
        case 1:
        case 2:
            return false;
        case 3:
            return true;
        case 7:
            return date === 0 || date === 6 ? true : false;
        case 8:
            return date === 0 || date >= 5 ? true : false;
        case 9:
            return date === 0 || date >= 4 ? true : false;
        case 10:
            return date === 0 || date >= 3 ? true : false;
    }
    return false;
}

export function CountdownModule({ params }: ModuleProps) {
    const [locInfoExport, setLocInfoExport] = useState<
        LocInfoSummer | LocInfoWinter
    >();
    const [now, setTime] = useState(new Date());

    useSitourExport(
        (params.get("season") === "summer"
            ? "loc_info_summer@"
            : "loc_info_winter@") + params.get("location"),
        setLocInfoExport,
        extractLocInfo,
        "countdown" + params.get("location")
    );

    useEffect(() => {
        const intervalId = setInterval(() => setTime(new Date()), 300);
        return () => clearInterval(intervalId);
    }, []);

    const start = opertimeToDate(locInfoExport?.opertime_start);
    const end = opertimeToDate(locInfoExport?.opertime_end);
    const open = now > start && now < end ? true : false;

    const textParam = open ? params.get("openText") : params.get("closeText");
    const textParamEN = open
        ? params.get("openTextEN")
        : params.get("closeTextEN");
    let text =
        textParam && textParamEN
            ? MultiLangStringsCustom([
                  textParam.toString(),
                  textParamEN.toString(),
              ])
            : "Chybí textové parametry";
    const diff = new Date(
        (open ? end.getTime() : start.getTime()) - now.getTime()
    );

    const backColor = params.get("backColor");
    if (backColor) document.body.style.background = backColor.toString();

    const timeBackground = params.get("timeBackground");
    if (timeBackground)
        text = text.replace(
            "<ZC>",
            "<div style='background-color:" +
                timeBackground.toString() +
                "' ><ZC></div>"
        );

    text = isOpen(locInfoExport?.operation_code)
        ? text.replace("<ZC>", dateToString(diff))
        : "Zavřeno";

    return (
        <div
            className="clockDiv"
            style={{
                marginTop: params.get("center") ? "auto" : "",
                marginBottom: params.get("center") ? "auto" : "",
                fontSize: params.get("size") + "px",
                color: params.get("textColor")?.toString(),
            }}
            dangerouslySetInnerHTML={{ __html: text }}
        />
    );
}
