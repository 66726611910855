import { Form } from "react-bootstrap";
import { ChangeEventHandler, useContext, useEffect } from "react";
import { ModuleSettingsContext } from "./ModuleSettings";
import { controlIdContext } from "./FormGroup";

export interface FormGroupCheckProps {
    checked?: boolean;
    onChange?: ChangeEventHandler;
    controlId?: string;
    label: string;
    myKey?: number;
}
export function FormGroupCheck({
    checked,
    onChange,
    label,
    controlId,
    myKey,
}: FormGroupCheckProps) {
    const setFormData = useContext(ModuleSettingsContext)[1];
    const controlIdContextValue = useContext(controlIdContext);
    const controlIdString = controlId ? controlId : controlIdContextValue;
    useEffect(() => {
        setFormData({
            type: "create",
            value: {
                name: controlIdString,
                value: checked || false,
                key: myKey,
            },
        });
        return () =>
            setFormData({
                type: "remove",
                name: controlIdString,
                key: myKey,
            });
    }, [setFormData, controlIdString, checked, myKey]);

    return (
        <Form.Check
            style={{ margin: "auto 0" }}
            id={controlIdString}
            name={controlIdString}
            label={label}
            onChange={(e) => {
                onChange && onChange(e);
                setFormData({
                    type: "modify",
                    value: {
                        name: controlIdString,
                        key: myKey,
                        value: e.currentTarget.checked,
                    },
                });
            }}
            defaultChecked={checked}
        />
    );
}
