import { Col, Row, Form } from "react-bootstrap";
import { FormGroup } from "../../components/FormGroup";
import { FormGroupColor } from "../../components/FormGroupColor";
import { FormGroupNumber } from "../../components/FormGroupNumber";
import { FormGroupSelect } from "../../components/FormGroupSelect";
import { FormGroupTextArea } from "../../components/FormGroupTextArea";
import { FormGroupCheckable } from "../../components/FormGroupCheckable";
import ModuleSettings from "../../components/ModuleSettings";
import { SelectLocation } from "../../components/SelectLocation";

export default function CountDownModuleSettings() {
  return (
    <ModuleSettings>
      <FormGroup labelText="Barva pozadí:">
        <FormGroupCheckable controlId="backColorCheck">
          <FormGroupColor controlId="backColor" />
        </FormGroupCheckable>
      </FormGroup>
      <FormGroup labelText="Barva textu:">
        <FormGroupCheckable controlId="textColorCheck">
          <FormGroupColor controlId="textColor" />
        </FormGroupCheckable>
      </FormGroup>
      <FormGroup labelText="Barva pozadí času:">
        <FormGroupCheckable controlId="timeBackgroundCheck">
          <FormGroupColor controlId="timeBackground" />
        </FormGroupCheckable>
      </FormGroup>
      <FormGroup labelText="Velikost textu:">
        <FormGroupNumber controlId="size" defaultValue={55} />
      </FormGroup>
      <SelectLocation />
      <FormGroup labelText="Sezóna:">
        <FormGroupSelect
          controlId="season"
          collection={[
            { value: "winter", text: "Zimní" },
            { value: "summer", text: "Letní" },
          ]}
          defaultValue={"winter"}
        />
      </FormGroup>
      <FormGroup labelText="Text Otevřeno:" block>
        <FormGroupTextArea
          controlId="openText"
          defaultValue={"Zbývá <ZC> do konce provozu"}
          rows={1}
        />
        <FormGroupTextArea
          controlId="openTextEN"
          defaultValue={"Remaining <ZC> until the end of operation"}
          rows={1}
        />
      </FormGroup>
      <FormGroup labelText="Text Zavřeno:" block>
        <FormGroupTextArea controlId="closeText" defaultValue={"Otevíráme za <ZC>"} rows={1} />
        <FormGroupTextArea controlId="closeTextEN" defaultValue={"Openning in <ZC>"} rows={1} />
      </FormGroup>
      <Row>
        <Col style={{ fontSize: "20px", textAlign: "center" }}>
          <Form.Text muted>
            &lt;br&gt; - nový řádek
            <br />
            &lt;ZC&gt; - zbývající čas
          </Form.Text>
        </Col>
      </Row>
    </ModuleSettings>
  );
}
