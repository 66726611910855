import { Accordion } from "react-bootstrap";
import { FormGroup } from "../../components/FormGroup";
import { FormGroupCheckable } from "../../components/FormGroupCheckable";
import { FormGroupColor } from "../../components/FormGroupColor";
import { FormGroupNumber } from "../../components/FormGroupNumber";
import { SelectLocation } from "../../components/SelectLocation";
import ModuleSettings from "../../components/ModuleSettings";

export default function WeatherModuleSettings() {
  return (
    <ModuleSettings noCenter>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Barvy</Accordion.Header>
          <Accordion.Body>
            <FormGroup labelText="Barva pozadí textu:">
              <FormGroupCheckable controlId="textBackgroundCheck">
                <FormGroupColor
                  controlId="textBackground"
                  defaultValue="#ccc"
                />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva textu:">
              <FormGroupCheckable controlId="textColorCheck">
                <FormGroupColor controlId="textColor" defaultValue="#eee" />
              </FormGroupCheckable>
            </FormGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <FormGroup labelText="Velikost textu:">
        <FormGroupNumber controlId="size" defaultValue={30} min={1} />
      </FormGroup>
      <SelectLocation />
    </ModuleSettings>
  );
}
