import { Form } from "react-bootstrap";
import { SelectOption } from "../definitions/General";
import { ChangeEventHandler, useContext, useEffect } from "react";
import { ModuleSettingsContext } from "./ModuleSettings";
import { controlIdContext } from "./FormGroup";

export interface FormGroupSelectProps {
    defaultValue?: string;
    collection?: Array<SelectOption>;
    onChange?: ChangeEventHandler<HTMLSelectElement>;
    controlId?: string;
    myKey?: number;
    dontUseForm?: boolean;
}
export function FormGroupSelect({
    defaultValue,
    collection,
    onChange,
    controlId,
    myKey,
    dontUseForm,
}: FormGroupSelectProps) {
    const setFormData = useContext(ModuleSettingsContext)[1];
    const controlIdContextValue = useContext(controlIdContext);
    const controlIdString = controlId ? controlId : controlIdContextValue;
    useEffect(() => {
        if (!dontUseForm)
            setFormData({
                type: "create",
                value: {
                    name: controlIdString,
                    value: defaultValue || "",
                    key: myKey,
                },
            });
        return () =>
            setFormData({
                type: "remove",
                name: controlIdString,
                key: myKey,
            });
    }, [setFormData, controlIdString, defaultValue, dontUseForm, myKey]);

    return (
        <Form.Select
            autoComplete="false"
            defaultValue={defaultValue}
            id={controlIdString}
            name={controlIdString}
            onChange={(e) => {
                onChange && onChange(e);
                if (!dontUseForm)
                    setFormData({
                        type: "modify",
                        value: {
                            name: controlIdString,
                            key: myKey,
                            value: e.currentTarget.value,
                        },
                    });
            }}
        >
            {collection?.map(({ value, text }) => (
                <option value={value} key={controlIdString + value}>
                    {text}
                </option>
            ))}
        </Form.Select>
    );
}
