import { FormGroup } from "../../components/FormGroup";
import { FormGroupCheckable } from "../../components/FormGroupCheckable";
import { FormGroupColor } from "../../components/FormGroupColor";
import { FormGroupNumber } from "../../components/FormGroupNumber";
import ModuleSettings from "../../components/ModuleSettings";
import { FormGroupCheck } from "../../components/FormGroupCheck";
import { Accordion } from "react-bootstrap";
import { SelectLocation } from "../../components/SelectLocation";
import { InfoItems } from "../../definitions/General";

export const ItemsStrings = {
  [InfoItems.snowHeightSlopes]: "VÝŠKA SNĚHU",
  [InfoItems.snowHeightNew]: "NOVÝ SNÍH",
  [InfoItems.snowTypeText]: "TYP SNĚHU",
  [InfoItems.snowHeightOutsideSlopes]: "SNÍH MIMO TRATĚ",
  [InfoItems.liftsInOperation]: "LANOVKY/VLEKY V PROVOZU",
  [InfoItems.snowedSlopes]: "ZASNĚŽOVANÉ TRATĚ",
  [InfoItems.crossCountryLength]: "BĚŽECKÉ TRATĚ",
  [InfoItems.nightSkiingTime]: "VEČERNÍ LYŽOVÁNÍ",
};

export default function CurrentInfoSettings() {
  return (
    <ModuleSettings>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Barvy</Accordion.Header>
          <Accordion.Body>
            <FormGroup labelText="Barva pozadí:">
              <FormGroupCheckable controlId="backColorCheck">
                <FormGroupColor controlId="backColor" />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva pozadí nadpisu:">
              <FormGroupCheckable controlId="headingBackgroundCheck">
                <FormGroupColor controlId="headingBackground" />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva pozadí textu:">
              <FormGroupCheckable controlId="textBackgroundCheck">
                <FormGroupColor controlId="textBackground" />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva textu nadpisu:">
              <FormGroupCheckable controlId="headingTextColorCheck">
                <FormGroupColor controlId="headingTextColor" />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva textu:">
              <FormGroupCheckable controlId="textColorCheck">
                <FormGroupColor controlId="textColor" />
              </FormGroupCheckable>
            </FormGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <FormGroup labelText="Velikost nadpisu:">
        <FormGroupNumber controlId="headingSize" defaultValue={25} min={1} />
      </FormGroup>
      <FormGroup labelText="Velikost textu:">
        <FormGroupNumber controlId="size" defaultValue={16} min={1} />
      </FormGroup>
      <SelectLocation />
      <FormGroup labelText="Počet sloupců:">
        <FormGroupNumber controlId="cols" defaultValue={2} min={1} />
      </FormGroup>
      <FormGroup labelText="Řádky stejné výšky:">
        <FormGroupCheck controlId="rowsSameSize" label="" checked />
      </FormGroup>
      <FormGroup labelText="Sloupce stejné šířky:">
        <FormGroupCheck controlId="colsSameSize" label="" checked />
      </FormGroup>
      <FormGroup labelText="Mezera mezi řádky:">
        <FormGroupNumber controlId="gap" defaultValue={5} min={0} />
      </FormGroup>
      <FormGroup labelText="Zobrazené položky:" block>
        {Object.entries(ItemsStrings).map(([key, value], i) => {
          return <FormGroupCheck controlId={key} label={value} key={i} myKey={i} checked />;
        })}
      </FormGroup>
      <FormGroup labelText="Mezičas (milisekundy):">
        <FormGroupNumber controlId="delay" defaultValue={1000} min={0} />
      </FormGroup>
      <FormGroup labelText="Rychlost:">
        <FormGroupNumber controlId="speed" defaultValue={100} min={0} />
      </FormGroup>
    </ModuleSettings>
  );
}
