import { Form } from "react-bootstrap";
import { ReactElement, useContext, useState } from "react";
import { controlIdContext } from "./FormGroup";

export interface FormGroupCheckableProps {
    children: ReactElement | ReactElement[];
    controlId?: string;
}
export function FormGroupCheckable({
    children,
    controlId,
}: FormGroupCheckableProps) {
    const [checked, setChecked] = useState(false);
    const controlIdContextValue = useContext(controlIdContext);
    const controlIdString = controlId ? controlId : controlIdContextValue;

    return (
        <>
            <Form.Check
                style={{ margin: "auto 10px auto 0" }}
                id={controlIdString}
                name={controlIdString}
                onChange={(e) => {
                    setChecked(e.target.checked);
                }}
                checked={checked}
            />
            {checked ? children : null}
        </>
    );
}
