import { Accordion, Badge } from "react-bootstrap";
import ModuleSettings from "../../components/ModuleSettings";
import { FormGroup } from "../../components/FormGroup";
import { FormGroupCheckable } from "../../components/FormGroupCheckable";
import { FormGroupColor } from "../../components/FormGroupColor";
import { FormGroupNumber } from "../../components/FormGroupNumber";
import { SelectLocation } from "../../components/SelectLocation";
import { useState } from "react";
import { FormGroupCheck } from "../../components/FormGroupCheck";

export function SlopesModuleSettings() {
  const [numberOfLocations, setNumberOfLocations] = useState(1);

  return (
    <ModuleSettings>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Barvy</Accordion.Header>
          <Accordion.Body style={{ padding: 0 }}>
            <FormGroup labelText="Barva pozadí:">
              <FormGroupCheckable controlId="backColorCheck">
                <FormGroupColor controlId="backColor" />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva pozadí nadpisu:">
              <FormGroupCheckable controlId="headingBackgroundCheck">
                <FormGroupColor controlId="headingBackground" />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva pozadí textu:">
              <FormGroupCheckable controlId="textBackgroundCheck">
                <FormGroupColor controlId="textBackground" />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva textu nadpisu:">
              <FormGroupCheckable controlId="headingTextColorCheck">
                <FormGroupColor controlId="headingTextColor" />
              </FormGroupCheckable>
            </FormGroup>
            <FormGroup labelText="Barva textu:">
              <FormGroupCheckable controlId="textColorCheck">
                <FormGroupColor controlId="textColor" />
              </FormGroupCheckable>
            </FormGroup>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Lokace</Accordion.Header>
          <Accordion.Body>
            <FormGroup labelText="Počet propojených lokací:">
              <FormGroupNumber
                controlId=""
                onChange={(e) => setNumberOfLocations(parseInt(e.currentTarget.value))}
                defaultValue={1}
                min={1}
                dontUseForm
              />
            </FormGroup>
            {new Array(numberOfLocations).fill(0).map((_, index) => {
              return (
                <Badge key={index} bg="secondary" className="mt-1 w-100">
                  <SelectLocation myKey={index} />
                </Badge>
              );
            })}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <FormGroup labelText="Velikost nadpisu:">
        <FormGroupNumber controlId="headingSize" defaultValue={25} min={1} />
      </FormGroup>
      <FormGroup labelText="Velikost textu:">
        <FormGroupNumber controlId="size" defaultValue={16} min={1} />
      </FormGroup>
      <FormGroup labelText="Počet sloupců:">
        <FormGroupNumber controlId="cols" defaultValue={2} min={1} />
      </FormGroup>
      <FormGroup labelText="Řádky stejné výšky:">
        <FormGroupCheck controlId="rowsSameSize" label="" checked />
      </FormGroup>
      <FormGroup labelText="Sloupce stejné šířky:">
        <FormGroupCheck controlId="colsSameSize" label="" checked />
      </FormGroup>
      <FormGroup labelText="Mezera mezi řádky:">
        <FormGroupNumber controlId="gap" defaultValue={5} min={0} />
      </FormGroup>
      <FormGroup labelText="Mezičas (milisekundy):">
        <FormGroupNumber controlId="delay" defaultValue={1000} min={0} />
      </FormGroup>
      <FormGroup labelText="Rychlost:">
        <FormGroupNumber controlId="speed" defaultValue={100} min={0} />
      </FormGroup>
    </ModuleSettings>
  );
}
