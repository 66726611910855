import { Form } from "react-bootstrap";
import { ChangeEventHandler, useContext, useEffect } from "react";
import { ModuleSettingsContext } from "./ModuleSettings";
import { controlIdContext } from "./FormGroup";

export interface FormGroupTextAreaProps {
    defaultValue?: any;
    onChange?: ChangeEventHandler;
    rows?: number;
    controlId?: string;
    myKey?: number;
}
export function FormGroupTextArea({
    defaultValue,
    onChange,
    controlId,
    rows,
    myKey,
}: FormGroupTextAreaProps) {
    const setFormData = useContext(ModuleSettingsContext)[1];
    const controlIdContextValue = useContext(controlIdContext);
    const controlIdString = controlId ? controlId : controlIdContextValue;
    useEffect(() => {
        setFormData({
            type: "create",
            value: {
                name: controlIdString,
                value: defaultValue || "",
                key: myKey,
            },
        });
        return () =>
            setFormData({
                type: "remove",
                name: controlIdString,
                key: myKey,
            });
    }, [setFormData, controlIdString, defaultValue, myKey]);
    return (
        <Form.Control
            id={controlIdString}
            name={controlIdString}
            defaultValue={defaultValue}
            onChange={(e) => {
                onChange && onChange(e);
                setFormData({
                    type: "modify",
                    value: {
                        name: controlIdString,
                        key: myKey,
                        value: e.currentTarget.value,
                    },
                });
            }}
            as="textarea"
            rows={rows}
            spellCheck={false}
        />
    );
}
