import { ParamsParser } from "../utils/ParamsParser";

enum Modules {
  None,
  CountDown = 1,
  CurrentInfo = 2,
  Camera = 3,
  Slopes = 4,
  Lifts = 5,
  Weather = 6,
  Skibuses = 7,
}

enum ControlTypes {
  Number,
  Color,
  Select,
  Textarea,
}

interface SelectOption {
  value?: string;
  text?: string;
}

interface ModuleProps {
  params: ParamsParser;
}

export enum InfoItems {
  snowHeightSlopes,
  snowHeightNew,
  snowTypeText,
  snowHeightOutsideSlopes,
  liftsInOperation,
  snowedSlopes,
  crossCountryLength,
  nightSkiingTime,
}

export { Modules, ControlTypes };
export type { SelectOption, ModuleProps };
