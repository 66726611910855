export type ParamValueType = string | number | boolean;

export type Param = {
  name: string;
  key?: number;
  value: ParamValueType;
};

function getParam(param: Param | string) {
  if (typeof param === "string") param = { name: param, value: 0 };
  return param;
}

export class ParamsParser {
  params: Array<Param> = [];

  constructor(search?: string, params?: Array<Param>) {
    if (params) this.params = params;
    if (!search) return;
    const inputs = decodeURIComponent(search)
      .replace("?", "")
      .split("&")
      .map((value) => value.split("="));

    inputs.forEach((item) => {
      let value: ParamValueType;
      if (item.length === 1 || item[1] === "true" || item[1] === "on")
        value = true;
      else if (item[1] === "false" || item[1] === "off") value = false;
      else if (isNaN(+item[1])) value = item[1];
      else value = parseInt(item[1]);
      this.params.push({ name: item[0], value: value });
    });
  }

  set(param: Param) {
    const index = this.params.findIndex(
      (item) => item.name === param.name && item.key === param.key
    );
    if (index !== -1) this.params[index] = param;
    else this.params.push(param);
  }

  get(param: Param | string) {
    const parsedParam = getParam(param);
    return (
      this.params.find((item) => item.name === parsedParam.name)?.value || false
    );
  }

  getAll(param: Param | string) {
    const parsedParam = getParam(param);
    return this.params
      .filter((item) => item.name === parsedParam.name)
      .map((item) => item.value);
  }

  toString() {
    const filtered = this.params.filter((i) =>
      typeof i.value === "boolean" ? i.value === true : true
    );
    return filtered
      .map((item) =>
        typeof item.value === "boolean"
          ? item.name
          : item.name + "=" + encodeURIComponent(item.value)
      )
      .join("&");
  }
}
