import { useState } from "react";
import { ModuleProps } from "../../definitions/General";
import { useAutoScroll } from "../../hooks/UseAutoScroll";
import { useSitourExport } from "../../hooks/UseSitourExport";
import { Slope } from "../../definitions/SitourExports";
import { Headers, MultiLangStrings } from "../../components/MultiLangStrings";
import { extractSlopes } from "../../definitions/SitourExportsParsers";

function getImageByStatus(status: number) {
    let open = false;
    const today = new Date().getDay();
    if (
        status === 2 ||
        (status === 7 && today % 6 === 0) ||
        (status === 8 && !(today % 6 === 0))
    )
        open = true;
    return open ? "./static/images/open.webp" : "./static/images/closed.webp";
}

enum Diffs {
    Blue = 1,
    Red = 2,
    Black = 3,
    BlueRed = 4,
    RedBlack = 5,
    BlueBlack = 6,
}

function getItemImgSlope(diff: Diffs) {
    switch (diff) {
        case Diffs.Blue:
            return "./static/svgs/slope_diff_blue.svg";
        case Diffs.Red:
            return "./static/svgs/slope_diff_red.svg";
        case Diffs.Black:
            return "./static/svgs/slope_diff_black.svg";
        case Diffs.BlueRed:
            return "./static/svgs/slope_diff_bluered.svg";
        case Diffs.RedBlack:
            return "./static/svgs/slope_diff_redblack.svg";
        case Diffs.BlueBlack:
            return "./static/svgs/slope_diff_blueblack.svg";
    }
}

export function SlopesModule({ params }: ModuleProps) {
    const [slopes, setSlopes] = useState<Array<Slope>>([]);

    const cols = Number(params.get("cols") ?? 2);
    const rows = Math.ceil(slopes.length / cols);

    let backColor = params.get("backColor");
    if (backColor) document.body.style.background = backColor.toString();

    const locations = params.getAll("location");
    useSitourExport(
        locations
            .map((value) => {
                return "loc_slopes@" + value;
            })
            .join(","),
        setSlopes,
        extractSlopes,
        "slopes" + locations.join()
    );

    useAutoScroll(
        Number(params.get("speed") ?? 100),
        Number(params.get("delay") ?? 2000)
    );

    return (
        <div
            className="content"
            style={{
                marginTop: params.get("center") ? "auto" : "",
                marginBottom: params.get("center") ? "auto" : "",
                fontSize: params.get("size") + "px",
                color: params.get("textColor").toString(),
                background: params.get("textBackground").toString(),
            }}
        >
            <div
                className="heading"
                style={{
                    fontSize: params.get("headingSize") + "px",
                    color: params.get("headingTextColor").toString(),
                    background: params.get("headingBackground").toString(),
                }}
            >
                <div>{MultiLangStrings(Headers.slopes)}</div>
            </div>
            <div
                className="info"
                style={{
                    gridTemplateColumns:
                        "repeat(" +
                        cols +
                        (params.get("colsSameSize") ? ",1fr)" : ",auto)"),
                    gridTemplateRows:
                        "repeat(" +
                        rows +
                        (params.get("rowsSameSize") ? ",1fr)" : ",auto)"),
                    gap: params.get("gap") + "px",
                }}
            >
                {slopes?.map((slope, index) => (
                    <div key={index}>
                        <span
                            style={{
                                display: "inline-block",
                                minWidth: "1.6em",
                                textAlign: "center",
                            }}
                        >
                            {slope._id}
                        </span>
                        <img
                            alt=""
                            src={getImageByStatus(Number(slope.status_code))}
                            style={{
                                width: "1em",
                                height: "1em",
                                marginRight: "0.3em",
                            }}
                        />
                        <img
                            alt=""
                            src={getItemImgSlope(Number(slope.diff_code))}
                            style={{
                                width: "1em",
                                height: "1em",
                                marginRight: "0.3em",
                            }}
                        />
                        <span>{slope.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}
