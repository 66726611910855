import "./App.css";
import ModulesSettingsMenu from "./components/ModulesSettingsMenu";
import ModulesSwitch from "./components/ModulesSwitch";
import { ParamsParser } from "./utils/ParamsParser";

export default function App() {
  const params = new ParamsParser(window.location.search);
  return params.get("loaded") ? (
    <ModulesSwitch params={params} />
  ) : (
    <ModulesSettingsMenu params={params} />
  );
}
