import { useEffect, useState } from "react";
import { ModuleProps } from "../../definitions/General";
import {
    LocInfo,
    LocCams,
    LocCrosscountry,
    LocInfoWinter,
    LocLifts,
    LocSlopes,
} from "../../definitions/SitourExports";
import { extractInfo } from "../../definitions/SitourExportsParsers";
import { useSitourExport } from "../../hooks/UseSitourExport";
import TextTransition, { presets } from 'react-text-transition';

interface TextReplaceType {
    key: string;
    value?: string;
}

function textReplace(text: string, map: Array<TextReplaceType>) {
    for (const { key, value } of map) {
        if (!text.includes(key)) continue;
        if (!value) return undefined;
        text = text.replace(key, value);
    }
    return text;
}

export default function CameraModule({ params }: ModuleProps) {
    const [sitourExport, setExport] = useState<
        LocInfo &
            LocInfoWinter &
            LocLifts &
            LocCrosscountry &
            LocSlopes &
            LocCams
    >();
    const [sentenses, setSentenses] = useState<Array<string>>([]);
	const [textIndex, setTextIndex] = useState(0);

    useSitourExport(
        "loc_info@*,loc_info_winter@*,loc_slopes@*,loc_lifts@*,loc_crosscountry@*,loc_cams@*".replaceAll(
            "*",
            params.get("location") + ""
        ),
        setExport,
        extractInfo,
        "currentInfo" + params.get("location")
    );

    useEffect(() => {
        async function fetchData() {
            const data = await fetch(
                "https://api.holidayinfo.cz/vety.php?alias=" +
                    params.get("location")
            );
            if (data.ok) setSentenses(await data.json());
        }
        if (params.get("location")) setTimeout(fetchData, 5 * 60 * 1000);
		   }, [params, setSentenses]);

    const cams = sitourExport?.cam;
    const cam = Array.isArray(cams)
        ? cams.find((cam) => cam._id === params.get("cam").toString())
        : cams;
    const camVideoFiles = cam?.media?.last_video?.videofile;
    const video = Array.isArray(camVideoFiles)
        ? camVideoFiles[0].__text
        : camVideoFiles?.__text;

    const map: Array<TextReplaceType> = [
        { key: "<OD>", value: sitourExport?.opertime },
        {
            key: "<VS>",
            value:
                sitourExport?.snowheight_slopes_min &&
                sitourExport?.snowheight_slopes_max
                    ? sitourExport?.snowheight_slopes_min +
                      "-" +
                      sitourExport?.snowheight_slopes_max
                    : "",
        },
        { key: "<T>", value: sitourExport?.temp_0700 },
        { key: "<WWW>", value: sitourExport?.contact_www },
        {
            key: "<OTime>",
            value: "<img alt=' ' style='height:2em' src='./static/svgs/hodiny-vector-white.svg'/>",
        },
        {
            key: "<OTemp>",
            value: "<img alt=' ' style='height:2em' src='./static/svgs/teplomer_blue.svg'/>",
        },
        {
            key: "<OVlocka>",
            value: "<img alt=' ' style='height:2em' src='./static/svgs/vlocka-blue.svg'/>",
        },
        { key: "<N>", value: cam?.name },
        { key: "<NV>", value: cam?.sealevel },
        { key: "<V1>", value: sentenses[0]?.replaceAll(" ", "&nbsp;") || " " },
        { key: "<V2>", value: sentenses[1]?.replaceAll(" ", "&nbsp;") || " " },
        { key: "<V3>", value: sentenses[2]?.replaceAll(" ", "&nbsp;") || " " },
        { key: "<V4>", value: sentenses[3]?.replaceAll(" ", "&nbsp;") || " " },
    ];

    const texts = params
        .getAll("text")
        .filter((text) => text.toString() !== "NaN")
        .map((text) => text.toString().replaceAll(" ", "&nbsp;"))
        .map((text) => textReplace(text, map))
        .map((text) => (text ? text.toString() : ""))
        .filter((text) => text !== "");

    const headingParam = params.get("heading");
    const heading = textReplace(
        headingParam ? headingParam.toString() : "",
        map
    );

	useEffect(() => {
	  if (texts.length > 1)
		setTimeout(() => {
			setTextIndex(textIndex + 1);
		}, Number(params.get("textTime")));
	}, [params,texts,textIndex,setTextIndex])
	
    console.log(texts);
    return (
        <div
            style={{
                width: "100vw",
                fontSize: params.get("size") + "px",
                color: params.get("textColor")
                    ? params.get("textColor")?.toString()
                    : "#000",
            }}
        >
            {params.get("logo") && (
                <div
                    style={{
                        position: "fixed",
                        top: "10vh",
                        right: "10vh",
                        width: "fit-content",
                    }}
                >
                    <img
                        alt=" "
                        style={{
                            width: "130px",
                            height: "130px",
                            fill: "#fff",
                        }}
                        src="./static/svgs/sitour-Logo2010_4c.svg"
                    />
                </div>
            )}
            <video autoPlay loop style={{ width: "100%" }}>
                {video && <source src={video} type="video/mp4" />}
            </video>
            <div
                style={{
                    position: "relative",
                    bottom: "50px",
                    transform: "translate(0,-100%)",
                    margin: "0 40px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        borderRadius: "25px 2px",
                        padding: "12px 5px",
                        width: "100%",
                        fontSize: params.get("textSize") + "px",
                        background: params.get("textBackground")
                            ? params.get("textBackground")?.toString() +
                              params.get("textBackgroundOpacity").toString(16)
                            : "#66e8",
                    }}
                >
                    <div
                        style={{
                            margin: "auto 0 auto 20px",
                            whiteSpace: "nowrap",
                            width: "fit-content",
                        }}
                    >
                        {heading || ""}
                    </div>
                    <div
                        style={{
                            margin: "10px auto",
                            display: "grid",
                            alignItems: "center",
                            justifyItems: "center",
                            textAlign: "center",
                            width: "100%",
                            height: Number(params.get("rows")) * 1.3 + "em",
                            fontSize: params.get("textSize") + "px",
                        }}
                    >
 						<TextTransition springConfig={presets.wobbly}><div style={{height: Number(params.get("rows")) * 1.5 + "em"}} dangerouslySetInnerHTML={{__html:texts[textIndex % texts.length]}}></div></TextTransition>
 </div>
                </div>
            </div>
        </div>
    );
}
