import { ReactNode, Reducer, createContext, useReducer } from "react";
import { Container, Col, Row, Form, Stack } from "react-bootstrap";
import { FormGroup } from "./FormGroup";
import { FormGroupCheck } from "./FormGroupCheck";
import { ModulePreview } from "./ModulePreview";
import { Param } from "../utils/ParamsParser";

interface Params {
    children?: ReactNode;
    noCenter?: boolean;
}

type FormInputAction =
    | { type: "create"; value: Param }
    | { type: "modify"; value: Param }
    | { type: "remove"; name: string; key?: number };

function FormInputsReducer(state: Array<Param>, action: FormInputAction) {
    switch (action.type) {
        case "create":
            if (
                state.every((e) => {
                    if (action.value.key)
                        return e.name !== action.value.name || e.key !== action.value.key;
                    else return e.name !== action.value.name;
                })
            )
                state = [...state, action.value];
            break;
        case "modify":
            const input = state.find(
                (input) => input.name === action.value.name && input.key === action.value.key
            );
            if (input) input.value = action.value.value;
            break;
        case "remove":
            state = state.filter(
                (input) => !(input.key === action.key && input.name === action.name)
            );
            break;
    }
    return [...state];
}

export const ModuleSettingsContext = createContext<[Array<Param>, React.Dispatch<FormInputAction>]>(
    [[], () => {}]
);

export default function ModuleSettings({ children, noCenter }: Params) {
    const [formData, setFormData] = useReducer<Reducer<Array<Param>, FormInputAction>>(
        FormInputsReducer,
        [{ name: "loaded", value: true }]
    );

    return (
        <ModuleSettingsContext.Provider value={[formData, setFormData]}>
            <Container fluid>
                <Row>
                    <Col>
                        <Form name="form" autoComplete="on">
                            <Stack
                                style={{
                                    marginTop: "40px",
                                    padding: "15px",
                                    background: "#ddd",
                                    border: "1px solid black",
                                    borderRadius: "10px",
                                    boxShadow: "5px black",
                                }}
                            >
                                {!noCenter && (
                                    <FormGroup labelText="Center:">
                                        <FormGroupCheck controlId="center" label="" />
                                    </FormGroup>
                                )}
                                {children}
                            </Stack>
                        </Form>
                    </Col>
                    <Col style={{ minWidth: "65vw" }}>
                        <ModulePreview />
                    </Col>
                </Row>
            </Container>
        </ModuleSettingsContext.Provider>
    );
}
